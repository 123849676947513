import {
  Box,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaTasks, FaUsers } from 'react-icons/fa';
import { FiBarChart2 } from 'react-icons/fi';
import { NavLink, useLocation } from 'react-router-dom';
import {
  activityIcon,
  agendaIcon,
  dashboardIcon,
  entityGoalsIcon,
  headPhoneIcon,
  langIcon,
  lightIcon,
  logoIcon,
  logoutIcon,
  repoIcon,
  settingsIcon,
  userIcon,
} from '../../../assests/icons';
import { useAppContext } from '../../../contexts/app-context';
import {
  LANGUAGES,
  useLanguage,
  useTranslate,
} from '../../../hooks/langs-hooks';
import { isClient, isStaff, isSystemUser } from '../../../utils/priviliages';
import './sidebar.scss';

interface SidebarProps { }

const Sidebar: React.FC<SidebarProps> = ({ }) => {
  const t = useTranslate();
  const { changeLang, lang: currentLang } = useLanguage();
  const { logout, user: me } = useAppContext();

  const { pathname } = useLocation();
  const [isRepoMenuOpen, setRepoMenuOpen] = useState(false);

  useEffect(() => {
    if (
      pathname === '/resources' ||
      pathname === '/custody-logs' ||
      pathname === '/receiving-logs' ||
      pathname === '/exchange-logs'
    ) {
      setRepoMenuOpen(true);
    } else {
      setRepoMenuOpen(false);
    }
  }, [pathname]);

  const isClerk = isSystemUser(me);
  const showDashboard = isStaff(me) || isClient(me);

  const showRepo =
    me?.privileges?.viewExchangeLogs ||
    me?.privileges?.custodays ||
    me?.privileges?.resources ||
    me?.privileges?.viewReceivingLogs;
  const showstorehouse = // take care
    me?.privileges?.viewExchangeLogs ||
    me?.privileges?.custodays ||
    me?.privileges?.resources ||
    me?.privileges?.viewReceivingLogs;
  const menuItems = [
    { icon: agendaIcon, text: t('text-agenda'), link: '/agenda' },
    // Add more menu items as needed
  ];

  return (
    <Box
      display='flex'
      flexDir='column'
      justifyContent='space-between'
      zIndex={10}
      overflow='hidden'
      transition='.5s ease'
      className='sidebar'
      py='3'
      overflowY={'auto'}
    >
      <Box>
        <HStack className='logo' px='2' py='1'>
          <Image src={logoIcon} boxSize='10' />
          <Text color='#fff' fontSize='1.3rem' fontWeight={500}>
          Matrix          </Text>
        </HStack>

        <VStack alignItems='flex-start' spacing={2}>
          {showDashboard && (
            <NavLink to='/' className='link'>
              <Image src={dashboardIcon} boxSize='5' />
              {t('text-dashboard')}
            </NavLink>
          )}
          {isClerk && (
            <NavLink to='/my-tasks' className='link'>
              <Icon as={FaTasks} boxSize={'5'} color='#8F9FD8' />
              {t('text-my-tasks')}
            </NavLink>
          )}
          {isStaff(me) && (
            <NavLink to='/departments' className='link'>
              <Image src={userIcon} boxSize='5' />
              {t('text-departments')}
            </NavLink>
          )}

          {me?.privileges?.viewProjects && (
            <Box
              className='link'
              onClick={() => setRepoMenuOpen((prev) => !prev)}
            >
              <Image src={lightIcon} boxSize='5' />
              {t('text-projects')}
            </Box>
          )}
          {isRepoMenuOpen && (
            <>
              <Box
                color='#fff'
                fontSize={'sm'}
                ps='5'
                display={'flex'}
                flexDir={'column'}
                gap={1}
                overflowX={'hidden'}
              >
                {me?.jobDescriptionName === "Employee" || "Owner" && (
                  <NavLink to='/projects' className={'sublink'}>
                    {t('text-projects')}
                  </NavLink>
                )}
              </Box>
              <Box
                color='#fff'
                fontSize={'sm'}
                ps='5'
                display={'flex'}
                flexDir={'column'}
                gap={1}
                overflowX={'hidden'}
              >
                {me?.jobDescriptionName === "Employee" || "Owner" && (
                  <NavLink to='/covenant-employee' className={'sublink'}>
                    {t('text-covenant')}
                  </NavLink>
                )}
              </Box>
            </>

          )}



          {isClerk && (
            <NavLink to='/agenda' className='link'>
              <Image src={agendaIcon} boxSize='5' />
              {t('text-agenda')}
            </NavLink>
          )}

          {/* hr-dashboard */}
          {(me?.jobDescriptionName === "Owner" || me?.jobDescriptionName === "HR") && (
            <NavLink to='/hr-dashboard' className='link'>
              <svg color='#8f9fd8' width="16" height="16" viewBox="0 0 44 33" fill="red" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.354 14.102C25.6904 12.2348 26.4089 9.99623 26.4089 7.70006C26.4089 5.4039 25.6904 3.16529 24.354 1.29808C25.6053 0.445648 27.0859 -0.00696153 28.6 8.09521e-05C30.6422 8.09521e-05 32.6007 0.811327 34.0447 2.25535C35.4888 3.69938 36.3 5.6579 36.3 7.70006C36.3 9.74222 35.4888 11.7007 34.0447 13.1448C32.6007 14.5888 30.6422 15.4 28.6 15.4C27.0859 15.4071 25.6053 14.9545 24.354 14.102ZM7.7 7.70006C7.7 6.17715 8.1516 4.68844 8.99768 3.42218C9.84377 2.15593 11.0463 1.169 12.4533 0.586207C13.8603 0.00341416 15.4085 -0.149071 16.9022 0.148034C18.3959 0.44514 19.7679 1.17849 20.8447 2.25535C21.9216 3.33222 22.6549 4.70422 22.952 6.19787C23.2492 7.69152 23.0967 9.23973 22.5139 10.6467C21.9311 12.0537 20.9442 13.2563 19.6779 14.1024C18.4116 14.9484 16.9229 15.4 15.4 15.4C13.3578 15.4 11.3993 14.5888 9.95528 13.1448C8.51125 11.7007 7.7 9.74222 7.7 7.70006ZM12.1 7.70006C12.1 8.35274 12.2935 8.99076 12.6562 9.53344C13.0188 10.0761 13.5341 10.4991 14.1371 10.7489C14.7401 10.9986 15.4037 11.064 16.0438 10.9366C16.6839 10.8093 17.2719 10.495 17.7335 10.0335C18.195 9.572 18.5093 8.98399 18.6366 8.34386C18.7639 7.70372 18.6986 7.0402 18.4488 6.43721C18.199 5.83421 17.7761 5.31883 17.2334 4.95622C16.6907 4.59361 16.0527 4.40007 15.4 4.40007C14.5248 4.40007 13.6854 4.74775 13.0665 5.36661C12.4477 5.98548 12.1 6.82485 12.1 7.70006ZM30.8 28.6V33H0V28.6C0 28.6 0 19.8 15.4 19.8C30.8 19.8 30.8 28.6 30.8 28.6ZM26.4 28.6C26.092 26.884 23.474 24.2 15.4 24.2C7.326 24.2 4.554 27.082 4.4 28.6M30.69 19.8C32.0384 20.8488 33.1409 22.1801 33.92 23.7003C34.6991 25.2206 35.136 26.8929 35.2 28.6V33H44V28.6C44 28.6 44 20.614 30.668 19.8H30.69Z" fill="#8f9fd8" />
              </svg>

              {t('text-hr')}
            </NavLink>
          )}



          {
            (me?.jobDescriptionName !== "Owner" && me?.jobDescriptionName !== "HR") && (
              <NavLink to='/hr-employee' className='link'>
                <svg color='#8f9fd8' width="16" height="16" viewBox="0 0 44 33" fill="red" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.354 14.102C25.6904 12.2348 26.4089 9.99623 26.4089 7.70006C26.4089 5.4039 25.6904 3.16529 24.354 1.29808C25.6053 0.445648 27.0859 -0.00696153 28.6 8.09521e-05C30.6422 8.09521e-05 32.6007 0.811327 34.0447 2.25535C35.4888 3.69938 36.3 5.6579 36.3 7.70006C36.3 9.74222 35.4888 11.7007 34.0447 13.1448C32.6007 14.5888 30.6422 15.4 28.6 15.4C27.0859 15.4071 25.6053 14.9545 24.354 14.102ZM7.7 7.70006C7.7 6.17715 8.1516 4.68844 8.99768 3.42218C9.84377 2.15593 11.0463 1.169 12.4533 0.586207C13.8603 0.00341416 15.4085 -0.149071 16.9022 0.148034C18.3959 0.44514 19.7679 1.17849 20.8447 2.25535C21.9216 3.33222 22.6549 4.70422 22.952 6.19787C23.2492 7.69152 23.0967 9.23973 22.5139 10.6467C21.9311 12.0537 20.9442 13.2563 19.6779 14.1024C18.4116 14.9484 16.9229 15.4 15.4 15.4C13.3578 15.4 11.3993 14.5888 9.95528 13.1448C8.51125 11.7007 7.7 9.74222 7.7 7.70006ZM12.1 7.70006C12.1 8.35274 12.2935 8.99076 12.6562 9.53344C13.0188 10.0761 13.5341 10.4991 14.1371 10.7489C14.7401 10.9986 15.4037 11.064 16.0438 10.9366C16.6839 10.8093 17.2719 10.495 17.7335 10.0335C18.195 9.572 18.5093 8.98399 18.6366 8.34386C18.7639 7.70372 18.6986 7.0402 18.4488 6.43721C18.199 5.83421 17.7761 5.31883 17.2334 4.95622C16.6907 4.59361 16.0527 4.40007 15.4 4.40007C14.5248 4.40007 13.6854 4.74775 13.0665 5.36661C12.4477 5.98548 12.1 6.82485 12.1 7.70006ZM30.8 28.6V33H0V28.6C0 28.6 0 19.8 15.4 19.8C30.8 19.8 30.8 28.6 30.8 28.6ZM26.4 28.6C26.092 26.884 23.474 24.2 15.4 24.2C7.326 24.2 4.554 27.082 4.4 28.6M30.69 19.8C32.0384 20.8488 33.1409 22.1801 33.92 23.7003C34.6991 25.2206 35.136 26.8929 35.2 28.6V33H44V28.6C44 28.6 44 20.614 30.668 19.8H30.69Z" fill="#8f9fd8" />
                </svg>

                {t('text-hr')}
              </NavLink>
            )
          }



          {me?.privileges?.viewClients && (
            <NavLink to='/clients' className='link'>
              <Icon as={FaUsers} boxSize={'5'} color={'#8F9FD8'} />
              {t('text-clients')}
            </NavLink>
          )}
          <NavLink to='/entity-goals' className='link'>
            <Image src={entityGoalsIcon} boxSize='5' />
            {t('text-entity-goals')}
          </NavLink>
          {me?.privileges?.viewActivityHistroy && (
            <NavLink to='/activity-history' className='link'>
              <Image src={activityIcon} boxSize='5' />
              {t('text-activity-history')}
            </NavLink>
          )}
          {me?.privileges?.viewReports && (
            <>
              <NavLink to='/reports' className='link'>
                <Icon as={FiBarChart2} boxSize={'5'} color='#8F9FD8' />
                {t('text-reports')}
              </NavLink>
            </>
          )}

          {showRepo && (
            <Box
              className='link'
              onClick={() => setRepoMenuOpen((prev) => !prev)}
            >
              <Image src={repoIcon} boxSize='5' />
              {t('text-repo')}
            </Box>
          )}
          {isRepoMenuOpen && (
            <Box
              color='#fff'
              fontSize={'sm'}
              ps='5'
              display={'flex'}
              flexDir={'column'}
              gap={1}
              overflowX={'hidden'}
            >
              {me?.privileges?.resources && (
                <NavLink to='/storehouses' className={'sublink'}>
                  {t('text-storeHouse')}
                </NavLink>
              )}
              {me?.privileges?.suppliers && (
                <NavLink to='/suppliers' className={'sublink'}>
                  {t('text-suppliers')}
                </NavLink>
              )}

              {me?.privileges?.resources && (
                <NavLink to='/resourceServices' className={'sublink'}>
                  {t('text-resources')}
                </NavLink>
              )}
              {/* {me?.privileges?.resources && (
                <NavLink to='/pricingResources' className={'sublink'}>
                  {t('text-PricingResources')}
                </NavLink>
              )}
              {me?.privileges?.resources && (
                <NavLink to='/exitResource' className={'sublink'}>
                  {t('text-exitResource')}
                </NavLink>
              )} */}

                {/* to be remove */}
              {me?.privileges?.resources && (
                <NavLink to='/Appropriations' className={'sublink'}>
                  {t('text-accreditation-requests')}
                </NavLink>
              )}




              {me?.privileges?.resources && (
                <NavLink to='/supplingResource' className={'sublink'}>
                  {t('text-supplingResource')}
                </NavLink>
              )}
              {me?.privileges?.resources && (
                <NavLink to='/inventoryResource' className={'sublink'}>
                  {t('text-inventoryResource')}
                </NavLink>
              )}
              {me?.privileges?.resources && (
                <NavLink to='/recoveryResource' className={'sublink'}>
                  {t('text-recoveryResource')}
                </NavLink>
              )}

                 {me?.privileges?.viewReports && (
                <NavLink to='/storeHouseReports' className={'sublink'}>
                  {t('text-storeHouseReports')}
                </NavLink>
              )}



              {/* {me?.privileges?.custodays && (
                <NavLink to='/custody-logs' className={'sublink'}>
                  {t('text-custody')}
                </NavLink>
              )}
              {me?.privileges?.viewReceivingLogs && (
                <NavLink to='/receiving-logs' className={'sublink'}>
                  {t('text-receive')}
                </NavLink>
              )}
              {me?.privileges?.viewExchangeLogs && (
                <NavLink to='/exchange-logs' className={'sublink'}>
                  {t('text-exchange')}
                </NavLink>
              )} */}
            </Box>
          )}



          {isClerk && (
            <NavLink to='/settings' className='link'>
              <Image src={settingsIcon} boxSize='5' />
              {t('text-settings')}
            </NavLink>
          )}
        </VStack>
      </Box>
      <VStack alignItems='flex-start' spacing={2}>
        <Box>
          <Menu>
            <MenuButton>
              <Box ps='1.5rem'>
                <Image
                  src={langIcon}
                  boxSize='5'
                  filter='brightness(1) saturate(100%) invert(4%) sepia(60%) saturate(6376%) hue-rotate(217deg) brightness(109%) contrast(107%)'
                />
              </Box>
            </MenuButton>
            <MenuList minW='10'>
              {LANGUAGES.map((lang) => (
                <MenuItem
                  key={lang.id}
                  onClick={changeLang.bind(null, lang)}
                  className={currentLang.id === lang.id ? 'active-lang' : ''}
                >
                  {t(lang.name)}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
        <NavLink to='/help-center' className='link'>
          <Image src={headPhoneIcon} boxSize='5' />
          {t('text-help-center')}
        </NavLink>
        <Box className='link' onClick={logout}>
          <Image src={logoutIcon} boxSize='5' />
          {t('text-logout')}
        </Box>
      </VStack>
    </Box>
  );
};

export default Sidebar;
