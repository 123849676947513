import {
  IAdminStatisticsResponse,
  IGetAdminStatisticsParams,
  IMyTasksOptions,
  IMyTasksResponse,
  IProject,
  IResource,
  ITask,
  Id,
} from '../../types';
import { BASE_URL } from '../../utils/constants';
//import { getToken } from '../../utils/storage';

import {
  addClient,
  addClientActivity,
  clientLogin,
  deleteClientActivity,
  editClient,
  editClientActivity,
  fetchClientsByName,
  getAllClientActivities,
  getAllClients,
  getClientProjects,
} from './endpoints/client.endpoints';
import {
  loginAsCompany,
  updateCompanyData,
} from './endpoints/company.endpoints';
import {
  createDepartment,
  deleteDepartment,
} from './endpoints/department.endpoints';
import {
  addClientCommentOnTask,
  addDepartmentsToProject,
  addProjectType,
  addTaskComment,
  addUsersToProject,
  addUsersToTask,
  createProject,
  createTask,
  deleteProject,
  deleteProjectDepartment,
  deleteProjectFile,
  deleteProjectType,
  deleteTask,
  deleteTaskClientComment,
  deleteTaskFile,
  deleteUserFromProject,
  deleteUserFromTask,
  editProjectType,
  fetchProjectTasks,
  getProject,
  getProjectDepartments,
  getProjectTypes,
  getProjectsByStatus,
  getProjectsByStatusAndDate,
  getTaskClientComments,
  getTaskComments,
  updateProject,
  updateTask,
} from './endpoints/project.endpoints';
import {
  getDelayTasksReport,
  getEmployeesReport,
  getProjectsReport,
  getTasksReport,
} from './endpoints/reports.endpoints';
import {
  getAllFeatures,
  addFeature,
  editFeature,
  deleteFeature,
} from './endpoints/features.endpoints';
import { HttpClient } from './http-client';
import { getAllStoreHouse } from './endpoints/storehouses.endpoint';

class Client {
  projectTypes = {
    all: getProjectTypes,
    create: addProjectType,
    edit: editProjectType,
    delete: deleteProjectType,
  };
  projects = {
    create: createProject,
    edit: updateProject,
    delete: deleteProject,
    getByStatus: getProjectsByStatus,
    getByStatusAndDate: getProjectsByStatusAndDate,
    get: getProject,
    deleteFile: deleteProjectFile,
    departments: getProjectDepartments,
    addEmployees: addUsersToProject,
    deleteEmployee: deleteUserFromProject,
    myProjects: (id: Id) =>
      HttpClient.get<Pick<IProject, 'projectId' | 'projectName'>[]>(
        BASE_URL+ `/api/Users/Select/User/Projects/${id}`
         ),
  };
  tasks = {
    create: createTask,
    edit: updateTask,
    delete: deleteTask,
    deleteFile: deleteTaskFile,
    addEmployees: addUsersToTask,
    deleteEmployee: deleteUserFromTask,
    adEmployeeComment: addTaskComment,
    getClientComments: getTaskClientComments,
    adClientComment: addClientCommentOnTask,
    deleteClientComment: deleteTaskClientComment,
    employeesComments: getTaskComments,
    project: fetchProjectTasks,
    myTasks: (options: IMyTasksOptions) =>
      HttpClient.post<IMyTasksResponse>(
        BASE_URL+`/api/Users/Select/User/Tasks`,
        options
       ),

    get: (id: Id) => HttpClient.get<ITask>( BASE_URL+`/api/Projects/Select/Task/${id}` ),
  };

  clients = {
    all: getAllClients,
    add: addClient,
    edit: editClient,
    search: fetchClientsByName,
    login: clientLogin,
    projects: getClientProjects,
  };

  clientActivities = {
    all: getAllClientActivities,
    add: addClientActivity,
    edit: editClientActivity,
    delete: deleteClientActivity,
  };
  features = {
    all: getAllFeatures,
    add: addFeature,
    edit: editFeature,
    delete: deleteFeature,
  };
  settings = {
    login: loginAsCompany,
    edit: updateCompanyData,
  };

  departments = {
    create: createDepartment,
    delete: deleteDepartment,
    addDepartmentToProject: addDepartmentsToProject,
    deleteDepartmentFromProject: deleteProjectDepartment,
  };

  repo = {
    resources: {
      projectResources: (projectId: number) =>
        HttpClient.get<IResource[]>(
          BASE_URL+  `/api/Repository/Select/Project/Resource/${projectId}`
           ),
    },
  };
  employees = {};
  reports = {
    tasks: getTasksReport,
    projects: getProjectsReport,
    delayedTasks: getDelayTasksReport,
    employees: getEmployeesReport,
  };
  StoreHousereports = {
    storehouse: getAllStoreHouse,
    // projects: getProjectsReport,
    // delayedTasks: getDelayTasksReport,
    // employees: getEmployeesReport,
  };

  admin = {
    statistics: ({ branchId, departmentId }: IGetAdminStatisticsParams) =>
      HttpClient.get<IAdminStatisticsResponse>(
        BASE_URL+`/api/Projects/Select/Dashpord/Statistics/${branchId}/${departmentId}`
     
   ),
  };
}

export default new Client();
