import {
  Select as ChakraSelect,
  FormControl,
  FormLabel,
  SelectProps,
  chakra,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import Select, { Props } from 'react-select';
import makeAnimated from 'react-select/animated';
import { useAppContext } from '../contexts/app-context';
import { fetchClientsByName } from '../data/client/endpoints/client.endpoints';
import {
  getAllemp,
  getResourcesByName,
  getSuppliersByName,
} from '../data/client/endpoints/repo.endpoints';
import { fetchUsersByUserNames } from '../data/client/endpoints/user.endpoints';
import { useDepartments } from '../data/department';
import { useTranslate } from '../hooks/langs-hooks';
import { IMultiSelectOption } from '../types';

const animatedComponents = makeAnimated();

interface MultiSelectProps extends Props {
  options: IMultiSelectOption[];
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, ...props }) => {
  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      options={options}
      isSearchable
      {...props}
    />
  );
};

interface MultiSelectUsersProps extends Props {}

export const MultiSelectUsers: React.FC<MultiSelectUsersProps> = (props) => {
  const [name, setName] = useState('');
  const { branch } = useAppContext();

  const usersQuery = useQuery({
    queryKey: ['users-names', name, branch?.branchId],
    queryFn: () =>
      fetchUsersByUserNames({ userName: name, branchId: branch?.branchId! }),
  });

  const options = usersQuery.data?.map((u) => {
    debugger;
    return {
      value: u.userId,
      label: `${u.userId} - ${u.userName} - ${u.jobDescriptionName}`,
    };
  });

  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      options={options}
      isSearchable
      isLoading={usersQuery.isLoading}
      onInputChange={(value) => {
        setName(value);
      }}
      {...props}
    />
  );
};

export const SearchSelectUser: React.FC<MultiSelectUsersProps> = (props) => {
  const [name, setName] = useState('');
  const { branch } = useAppContext();

  const usersQuery = useQuery({
    queryKey: ['users-names', name, branch?.branchId],
    queryFn: () =>
      fetchUsersByUserNames({ userName: name, branchId: branch?.branchId! }),
  });

  const options = usersQuery.data?.map((u) => {
    return {
      value: u.userId,
      userId: u.userId,
      label: `${u.userId} - ${u.userName} - ${u.jobDescriptionName}`,
    };
  });

  return (
    <Select
      closeMenuOnSelect={true}
      components={animatedComponents}
      options={options}
      isSearchable
      isLoading={usersQuery.isLoading}
      onInputChange={(value) => {
        setName(value);
      }}
      {...props}
    />
  );
};
 
export const MultiSelectUsersEmp: React.FC<MultiSelectUsersProps> = (props) => {
  const [name, setName] = useState('');
  const { branch } = useAppContext();

  const usersQuery = useQuery({
    queryKey: ['users-names', name, branch?.branchId],
    queryFn: () =>
      getAllemp({ page: 1, count: 10000}),
  });

  const options = usersQuery.data?.items.map((u) => {
    return {
      value: u.userId,
      label: `${u.userId} - ${u.name} `,
    };
  });

  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      options={options}
      isSearchable
      isLoading={usersQuery.isLoading}
      onInputChange={(value) => {
        setName(value);
      }}
      {...props}
    />
  );
};

export const SelectClient: React.FC<MultiSelectUsersProps> = (props) => {
  const [name, setName] = useState('');
  const { branch } = useAppContext();

  const clientsQuery = useQuery({
    queryKey: ['clients-by-names', name],
    queryFn: () => fetchClientsByName(name, branch?.branchId ?? 0),
  });

  const options = clientsQuery.data?.clients.map((c) => {
    return {
      value: c.clientId,
      label: `${c.clientId} - ${c.clientName}`,
    };
  });

  return (
    <Select
      closeMenuOnSelect={true}
      components={animatedComponents}
      isMulti
      options={options}
      isSearchable
      isLoading={clientsQuery.isLoading}
      onInputChange={(value) => {
        setName(value);
      }}
      {...props}
    />
  );
};

export const SearchSelectResource: React.FC<MultiSelectUsersProps> = ({
  value,
  ...props
}) => {
  const [name, setName] = useState('');

  const resourcesQuery = useQuery({
    queryKey: ['resource-by-names', name],
    queryFn: () =>
      getResourcesByName({
        type: 'all',
        page: 1,
        count: 50,
        search: name,
      }),
  });

  const options = resourcesQuery.data?.resources.map((r) => {
    return {
      value: r.resourceId,
      label: r.resourceName,
      resourceName: r.resourceName,
      resourceId: r.resourceId,
    };
  });

  const selectedOption = options?.find((o) => o.value === value);

  return (
    <Select
      value={selectedOption}
      closeMenuOnSelect={true}
      components={animatedComponents}
      isMulti={false}
      options={options}
      isSearchable
      isLoading={resourcesQuery.isLoading}
      onInputChange={(value) => {
        setName(value);
      }}
      {...props}
    />
  );
};

export const SearchSelectSupplier: React.FC<MultiSelectUsersProps> = ({
  value,
  ...props
}) => {
  const [name, setName] = useState('');

  const suppliersQuery = useQuery({
    queryKey: ['suppliers-by-names', name],
    queryFn: () =>
      getSuppliersByName({
        type: 'all',
        page: 1,
        count: 50,
        search: name,
      }),
  });

  const options = suppliersQuery.data?.items.map((s) => {
    return {
      value: s.supplierId,
      label: s.supplierName,
      supplierName: s.supplierName,
      supplierId: s.supplierId,
    };
  });

  const selectedOption = options?.find((o) => o.value === value);

  return (
    <Select
      value={selectedOption}
      closeMenuOnSelect={true}
      components={animatedComponents}
      isMulti={false}
      options={options}
      isSearchable
      isLoading={suppliersQuery.isLoading}
      menuPosition='fixed'
      onInputChange={(value) => {
        setName(value);
      }}
      {...props}
    />
  );
};

export const SelectDepartment: React.FC<SelectProps> = (props) => {
  const t = useTranslate();
  const departmentsQuery = useDepartments();

  return (
    <FormControl>
      <FormLabel>{t('text-dept')}</FormLabel>
      <ChakraSelect
        variant={'outline'}
        isRequired
        placeholder={t('text-all-depts')}
        {...props}
      >
        {departmentsQuery.data?.map((department) => (
          <option key={department.departmentId} value={department.departmentId}>
            {department.departmentName}
          </option>
        ))}
      </ChakraSelect>
    </FormControl>
  );
};

export default chakra(MultiSelect);
